<template>
  <div></div>
</template>

<script>
export default {
  layout: 'home',
  mounted() {
    this.$router.push({ path: '/carteiras' })
  },
}
</script>
<style lang="scss"></style>
